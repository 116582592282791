<div [ngClass]="stickyClass">
  <div class="header" [ngClass]="bgClass">
    <div class="container py-3 px-0">
      <div class="d-flex justify-content-between align-items-center">
        <div class="d-flex align-items-center">
          <i
            *ngIf="addHamburgerMenu && !!leftNavigate"
            class="col-auto bi bi-list cursor-pointer me-2 ham-menu"
            (click)="toggleDrawer.emit('left')"
          ></i>
          <a *ngIf="!!logo2 && header.dynamicLogo" [routerLink]="'/home'">
            <img class="img-fluid img--logo1" [src]="logo2" />
          </a>
          <a
            *ngIf="(!header.dynamicLogo || !logo2) && !!header.firstLogo"
            [routerLink]="'/home'"
          >
            <img
              class="img-fluid"
              [ngStyle]="logoStyle"
              src="assets/img/org/logo-header.svg"
            />
          </a>
          <a *ngIf="!!header.secondLogo" [routerLink]="'/home'">
            <img class="img-fluid img--logo2" [src]="header.secondLogo" />
          </a>
        </div>

        <app-top-menu
          *ngIf="enabledModuleCounts > 1 && topMenu"
          class="top--menu"
        ></app-top-menu>

       <app-timer class="d-none d-lg-block"></app-timer>


        <div class="header--right-section">
          <div class="d-flex">
            <img
              *ngIf="header.pointDetail"
              class="cursor-pointer"
              src="assets/img/icon_points.png"
              width="37px"
              height="36px"
              (click)="toggleDrawer.emit('right')"
            />
            <app-notif *ngIf="header.notification" class="col-auto"></app-notif>

            <app-cart-header
              *ngIf="modules.cart"
              [cartCount]="cartCount"
              [cartDetails]="cartDetails"
              [cartItems]="cartItems"
              [cartStatus]="cartStatus"
              class="col-auto mx-2"
            ></app-cart-header>

            <app-language-change
              *ngIf="languageAvailable.length > 1 && !isLoginTnc"
              class="col-auto lang mx-2"
              [mode]="LanguageMode.iconDropdown"
              [showNotif]="true"
              withPadding="1"
              [isIconSVG]="header.isIconSVG"
            ></app-language-change>

            <div class="profile-tab mx-2" *ngIf="showProfile">
              <app-profile *ngIf="!isLoginTnc" class="col-auto profile-menu">
              </app-profile>
              <span class="profile-title">
                {{ "common.profile._" | translate }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="cross-section">
      <div class="full--box">
        <div class="cross-box"></div>
      </div>
    </div>
  </div>
  <app-breadcrumb
    *ngIf="!!segments.length"
    [segments]="segments"
  ></app-breadcrumb>
</div>
